<template>
  <div class="container-idle" v-if="show">
    <div class="content-idle" :style="{'background': mountedSurvey.colors.endMessageBg ? mountedSurvey.colors.endMessageBg : '#FFFFFF'}">
      <div class="section-idle">
        <app-heading level="h3" :style="{'color': mountedSurvey.colors.endMessageColor ? mountedSurvey.colors.endMessageColor : '#000000'}">{{ $t('inativity.are_you_there') }}</app-heading>
        <app-heading level="h4" :style="{'color': mountedSurvey.colors.endMessageColor ? mountedSurvey.colors.endMessageColor : '#000000'}">{{ $t('inativity.redirect_idle') }} {{ time }}s</app-heading>
        <img v-if="mountedSurvey.images && mountedSurvey.images[0].value" :src="mountedSurvey.images[1].value" alt="small-logo">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Idle',
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      show: false,
      time: 10,
      remaining: null
    }
  },
  mounted () {
    const urlbase = `${window.location.origin}/#`
    if (this.mountedSurvey.initialRoute && this.mountedSurvey.initialRoute.url) {
      const link = this.mountedSurvey.initialRoute.url
      let mountLink

      if (link && link.length > 0) {
        mountLink = `${urlbase}${link}`
      }

      this.$root.$on('is-idle', payload => {
        this.show = payload.show
        if (payload.show) {
          this.remaining = setInterval(() => {
            this.time--
            if (this.time <= 0) {
              clearInterval(this.remaining)
              this.$root.$emit('stop-idle')
              window.location.href = mountLink
            }
          }, 1000)
        } else {
          clearInterval(this.remaining)
          this.time = 10
        }
      })
    }
  },
  computed: {
    mountedSurvey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    }
  },
  beforeDestroy () {
    this.time = 10
    this.$root.$emit('stop-idle')
  }
}
</script>

<style lang="scss">
.container-idle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.10);
  display: flex;
  align-items: flex-end;
  justify-content: right;
  font-size: 100px;
  animation: showAlert 0.5s linear forwards;
  .content-idle {
    position: absolute;
    width: 100%;
    height: 250px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.23);
    display: flex;
    align-items: center;
    justify-content:  center;
    animation: loadingIdle 0.5s linear forwards;
    .section-idle {
      margin-top: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      img {
        max-width: 100px;
        padding-bottom: 20px;
      }
    }
  }
}

// responsivity to mobile
@media only screen and (max-width: 850px) {
.container-idle {
  .content-idle {
    .section-idle {
      img {
        max-width: 50px;
        padding-bottom: 20px;
      }
    }
  }
}
}

@keyframes loadingIdle {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0%;
  }
}

@keyframes showAlert {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
